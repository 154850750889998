// TODO: This file was created by bulk-decaffeinate.
// Sanity-check the conversion and remove this comment.
module.exports = {
  jQueryEvent: {
    title: 'jQuery Event',
    id: 'jQueryEvent',
    $schema: 'http://json-schema.org/draft-04/schema#',
    description: 'A standard jQuery Event',
    type: 'object',
    properties: { // TODO schema complete
      altKey: {
        type: 'boolean'
      }
    },
    required: [],
    additionalProperties: true
  }
}
